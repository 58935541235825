<template>
  <div class="practiceExam">
    <div class="content">
      <el-scroll
        class="box"
        :fixed-style="{ top: 78, right: 0 }"
        :scroll-target="''"
      >
        <!-- <div
          slot="fixed"
          class="content-right"
          style="width: 350px; min-height: 560px"
        >
          <div class="contDown" v-show="!noTime">
            <div>剩余时间：</div>
            <CountDown
              class="time"
              ref="CountDown"
              :infos="timeInfos"
              @timeStamp="handleTimeStamp"
            >
            </CountDown>
          </div>
          <!== <div style="width: 100%">
            <TopicList
              @topicClick="topicClick"
              ref="TopicList"
              :topicParam="topicParam"
              :pitchNums="pitchNums"
            ></TopicList>
            <HotRecommend
              :title="examType == '比赛' ? '热门推荐' : '推荐题目'"
              class="recommend"
              :type="examType == '比赛' ? 'questList' : 'question'"
            ></HotRecommend>
          </div> ==>
        </div> -->
        <div style="margin-left: 20px; width: 100%">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <Breadcrumb></Breadcrumb>
            <div class="contDown" v-show="!noTime" style="display: flex">
              <!-- <div class="contDown" > -->
              <div class="sameStyle">剩余时间：</div>
              <CountDown
                class="time"
                ref="CountDown"
                :infos="timeInfos"
                @overTime="overTime"
                @timeStamp="handleTimeStamp"
              >
              </CountDown>
              <!-- <el-statistic
                ref="statistic"
                @finish="hilarity"
                format="HH:mm:ss"
                :value="startTimeStamp"
                time-indices
              >
              </el-statistic> -->
            </div>
          </div>
          <div class="content-left">
            <!-- <div class="course-title">
              <div style="width: 80%">
                <div class="name" v-html="detailList.title"></div>
                <div class="detailed">
                  <span
                    >{{ detailList.type_title || "题目" }}提供者：{{
                      detailList.user_real_name
                    }}</span
                  >
                  <span>难度：{{ detailList.diff_title }}</span>
                  <span>提交：{{ detailList.log_num }}</span>
                  <span>通过：{{ detailList.jige_total }}</span>
                  <span
                    v-if="examType == '比赛' && detailList.type_title == '比赛'"
                    >比赛时间：{{ detailList.start_time_f }}</span
                  >
                </div>
              </div>
              <div class="edit">
                <div
                  class="feedback"
                  @click="
                    handleCommand(
                      'feedback',
                      detailList.title,
                      examTypes.indexOf(examType) > -1
                        ? detailList.type_title
                        : '题单'
                    )
                  "
                >
                  <i class="el-icon-edit-outline"></i>反馈
                </div>
                <el-button
                  size="small"
                  style="margin-left: 20px"
                  type="primary"
                  @click="submit"
                  >{{ headrBtn }}</el-button
                >
              </div>
            </div> -->
            <!-- //题目列表 -->
            <!-- <TopicSum v-loading="loading"
                    ref="TopicSum"
                    :element-loading-text="loadingText"
                    :discussShow="discussShow"
                    :status="statusTopic"
                    :detailList="detailList"
                    :deploy="deploy"
                    v-model="answers"
                    @setPitchNums="pitchNums = $event"
          ></TopicSum> -->

            <!-- <TopicSum
              v-loading="loading"
              ref="TopicSum"
              :element-loading-text="loadingText"
              :discussShow="discussShow"
              :status="statusTopic"
              :detailList="detailList"
              :deploy="deploy"
              :noTime="noTime"
              :duration="duration"

              @setAnswers="setAnswers"
              @submit="submit"
              @setPitchNums="pitchNums = $event"
            ></TopicSum> -->
            <!-- 混合题上下题切换 -->
            <newTopicSum
              v-loading="loading"
              ref="TopicSum"
              :element-loading-text="loadingText"
              :discussShow="discussShow"
              :status="statusTopic"
              :detailList="detailList"
              :stagingAnswerList="stagingAnswerList"
              :deploy="deploy"
              :noTime="noTime"
              :duration="duration"
              @setAnswers="setAnswers"
              @submit="submit"
              @setPitchNums="pitchNums = $event"
            ></newTopicSum>
          </div>
        </div>
      </el-scroll>
    </div>
    <SubmitPop
      ref="SubmitPop"
      @confirmPop="confirmPop"
      @cancelPop="cancelPop('cancel')"
      @closePop="cancelPop('close')"
    >
      <div
        v-if="pitchNums.length < topicParam.topicList.length"
        style="color: rgba(233, 75, 50, 1)"
      >
        <!-- 你还有试题没有做完，确定提交吗？ -->
        请确认所有题目都已作答，是否提交？
      </div>
      <div v-else>确定提交吗？</div>
    </SubmitPop>
    <SubmitPop
      ref="SubmitPopExit"
      @confirmPop="confirmPop"
      @cancelPop="cancelPop('cancel')"
      @closePop="cancelPop('close')"
      confirmButtonText="提交答案"
      cancelButtonText="离开页面"
    >
      <div style="color: rgba(233, 75, 50, 1)">{{ returnVal }}</div>
    </SubmitPop>
    <el-backtop :bottom="100" :right="400" :visibility-height="400">
      <div class="go-top">
        <i class="el-icon-caret-top" style="color: #00957e"></i>
        <div style="margin-top: -8px">顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import CountDown from "@/components/CountDown/CountDown.vue";
import SubmitPop from "@/components/SubmitPop/SubmitPop.vue";
import {
  detailList,
  bisaidetail,
  bisaianswer,
  bisaianswercache,
} from "@/api/problemList.js";
import { otherAnswer } from "@/api/common.js";
import { question_log_answer } from "@/api/topic.js";
import TopicSum from "@/components/TopicSum/TopicSum.vue";
import newTopicSum from "@/components/TopicSum/newTopicSum.vue";
import { Base64 } from "js-base64";
import { formateTimeStamp } from "@/components/CountDown/tools.js";
import store from "@/store";
import ElScroll from "@/components/com/el/elScroll";
var _ = require("lodash");

export default {
  components: {
    ElScroll,
    TopicList,
    TopicSum,
    HotRecommend,
    CountDown,
    SubmitPop,
    newTopicSum,
  },
  data() {
    return {
      deploy: { isAnswer: false },
      loading: false,
      timeInfos: [0, 0],
      detailList: {},
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      pitchNums: [],
      answers: [],
      start_time: 0,
      id: "",
      statusTopic: "preview",
      loadingText: "",
      headrBtn: "提交",
      CurrentDuration: 0,
      duration: false, //是否有时长

      log_id: "",
      noTime: false,
      examTypeC: "list",
      examType: "",
      examTypes: ["比赛", "作业", "考试"],
      discussShow: false,
      questionsNum: 0, //题目总数
      cancelButtonText: false,
      is_log_answer: false, //是否为查看答题详情模式
      returnVal: null,
      leftKey: "kjihgJIKHDCBAGFEdefcab",
      rightKey: "ABCDEFGHIJKabcdefghijk",
      userAuth: {
        user_id: localStorage.getItem("user_id"),
        user_token: localStorage.getItem("user_token"),
      },
      oldData: {},
      answer_time: 0,
      routeLeave: 0,
      socketListenPath: null,
      stagingAnswerList: {},
      intervalId: null,
      startExam: false, //是否开始考试
      startTimeStamp: 0, //倒计时
      startTimeLine: 0, //获取开始答题时候时间戳
    };
  },
  watch: {
    // socketListenPath(v, o) {
    //   if (o) {
    //     this.handlerSocketInit(true, o);
    //   }
    //   if (v && v + "" !== o + "") {
    //     this.handlerSocketInit(false, v);
    //   }
    // },
  },
  beforeRouteLeave: function (to, from, next) {
    this.handleBeforeRouteLeave(next, to, from);
  },
  created() {
    // console.log(this.answer,"v-model绑定");
    // if(this.startExam){
      this.intervalId = setInterval(this.callApi, 1000 * 60*3.5);
    // }
    console.log(Date.now(), "Date.now()");
    console.log(
      new Date().setHours(23, 59, 59) - Date.now(),
      "Date.now()!!!!!!!!!!!"
    );
    var _this = this;
    window.addEventListener("beforeunload", function () {
      return _this.handleBeforeRouteLeave(null, true);
    });
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  mounted() {
    // if(location.href.indexOf("#reloaded")==-1){
    //     location.href=location.href+"#reloaded";
    //     location.reload();
    // }
    let query = this.$route.query;
    // console.log(query,"queryquery")
    this.id = query.id;
    this.examType = query.type || "题单";
    this.log_id = query.log_id;
    this.duration = query.duration;
    // console.log(this.examType,"this.examType")
    //是否显示倒计时
    this.noTime = parseInt(query.noTime) === 1;
    this.loading = true;
    this.routeLeave = 0;
    if (this.examTypes.indexOf(this.examType) > -1) {
      // console.log('不是题单')
      this.examTypeC = "other";
      if (this.$route.path.indexOf("/match/") !== 0) return this.$routerGo(-1);
      this.getBisaidetail();
      this.getStagingAnswer();
    } else {
      this.examTypeC = "list";

      if (this.$route.path.indexOf("/problemList/") !== 0)
        return this.$routerGo(-1);
      // 是否为查看答题详情状态
      if (query.is_log_answer == 1) {
        this.is_log_answer = true;
        this.log_answer(query.log_id);
      }
      this.getDetailList();
      this.getStagingAnswer();
    }
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false);
    store.state.loginOutCallback = () => {
      this.LocalStorageCurrent(true);
      this.LocalStorageCurrent(0);
    };
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler, false);
  },
  methods: {
    callApi() {
      // 这里是调用接口的方法
      console.log("调用接口");
      console.log(new Date().getTime(), "new Date().getTime()");
      this.$http
        .post(`/api/v1_0_0.question_other/residueTime`, {
          other_id: this.id,
          start_time: this.startTimeLine,
          type: "other",
        })
        .then((res) => {
          console.log(res, "获取剩余时间");
          // this.startTimeStamp = Date.now() + 40 * 1000*1000;
          console.log(res.data.residue, "获取剩余时间");
          console.log(this.startTimeStamp, "获取剩余时间");
          try {
            this.$refs.CountDown.timeInfos = [res.data.residue, 0];
          } catch (e) {}
          this.$refs.CountDown.mistiming();
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });

      this.$forceUpdate()
    },
    hilarity() {
      // if (this.intervalId) {
      //   clearInterval(this.intervalId);
      // }
      this.$message({
        message: "答题时间已结束",
        type: "warning",
      });
    },
    beforeUnloadHandler(event) {
      console.log(event, "event55555");
      // this.handlerSocketInit(true, this.socketListenPath);
      if (this.returnVal) {
        this.LocalStorageCurrent(true);
      }
      // event.returnValue = this.returnVal;
    },
    handlerSocketInit(isDel = false, url = null) {
      if (!this.socketListenPath && !url) return;
      if (isDel === false) {
        // this.$socket
        //   .listen(url || this.socketListenPath, {}, ({ data }) => {
        //     if (data.activity_status === -1) {
        //       this.statusTopic = "preview";
        //       this.detailList.activity_status = data.activity_status;
        //       this.detailList.activity_msg =
        //         data.activity_msg || this.examType + "已结束";
        //       this.$refs.CountDown && (this.$refs.CountDown.suspend = true);
        //       this.$message.error(this.detailList.activity_msg);
        //       this.confirmPop();
        //     }
        //   })
        //   .connect();
      } else {
        // this.$socket.delListen(url || this.socketListenPath);
      }
    },
    returnValGet() {
      var msg;
      if (
        localStorage.getItem("user_id") > 0 &&
        !this.log_id &&
        this.headrBtn === "提交" &&
        this.cancelButtonText
      ) {
        var wzNum =
          this.topicParam.topicList.length -
          this.pitchNums.filter((d) => {
            return !(d.my_answer instanceof Array
              ? d.my_answer.join()
              : d.my_answer);
          }).length;
        if (this.detailList.duration > 0 && !this.noTime) {
          if (this.CurrentDuration > 10) {
            //msg = wzNum > 0 ? "你的题还未做完，确定要离开吗？" : "你的题已做完，是否提交答案？";
            msg = "你的题还没提交，确定要离开吗？";
          } else if (this.CurrentDuration > 0) {
            msg = "只剩下最后" + this.CurrentDuration + "秒，确定要离开吗？";
          }
        } else if (["比赛", "考试"].indexOf(this.examType) > -1) {
          msg = "退出后不可再答题了，确定要离开吗?";
        } else {
          msg =
            wzNum > 0
              ? "你的题还未做完，确定要离开吗？"
              : "你的题已做完，是否提交答案？";
          //msg = '你的题还没提交，确定要离开吗？';
        }
      }
      this.returnVal = msg;
      return this.returnVal;
    },
    handleBeforeRouteLeave(next, to) {
      var msg = this.returnValGet();
      if (msg) {
        this.$refs.CountDown.suspend = true;
        this.LocalStorageCurrent(true);
        if (to !== true) {
          this.$refs.SubmitPopExit.show = true;
          this.routeLeave = next;
        } else {
          next && next(false);
        }
      } else {
        next && next();
      }
      return msg;
    },
    overTime() {
      console.log("时间结束~~~~");
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.$message({
        message: "答题时间已结束",
        type: "warning",
      });
      // return;
      this.confirmPop();
    },
    handleTimeStamp(time) {
      // console.log(time,"time")
      this.CurrentDuration = time;
      // setTimeout(() => {
      //   this.timeInfos = [60, 0];
      // }, 5000);
      // console.log(this.timeInfos ,"time")

      // if (
      //   this.detailList.duration > 0 &&
      //   (time <= 0 ||
      //     (this.detailList.property == 1 &&
      //       this.detailList.end_time <= parseInt(new Date().getTime() / 1000)))
      // ) {

      //   var yzNum = this.pitchNums.filter((d) => {
      //     return !(d.my_answer instanceof Array
      //       ? d.my_answer.join()
      //       : d.my_answer);
      //   }).length;
      //   console.log(yzNum,"yzNumyzNum")
      //   var msg = time <= 0 ? "答题倒计时已结束" : "答题时间已结束";
      //   this.$message.warning(
      //       this.examType + "【" + this.detailList.title + "】" + msg + "！！"
      //     );
      //     this.confirmPop();
      //   // if (yzNum > 0) {
      //   //   this.$message.warning(
      //   //     this.examType + "【" + this.detailList.title + "】" + msg + "！！"
      //   //   );
      //   //   this.confirmPop();
      //   // } else {
      //   //   var times = formateTimeStamp(
      //   //     this.answer_time || this.detailList.duration
      //   //   );
      //   //   this.endConfirmPop( this.examType +
      //   //       "【" +
      //   //       this.detailList.title +
      //   //       "】" +
      //   //       msg +
      //   //       ",答题时长为" +
      //   //       [
      //   //         times.day > 0 ? times.day : 0,
      //   //         times.hour > 0 ? times.hour : 0,
      //   //         times.min,
      //   //         times.seconds,
      //   //       ]
      //   //         .filter((d) => d)
      //   //         .join(":") +
      //   //       "，已答题" +
      //   //       yzNum +
      //   //       "，道是否提交答案？",
      //   //     null,
      //   //     true
      //   //   );
      //   // }
      // } else {
      //   this.answer_time++;
      // }
      this.returnValGet();
    },
    //

    endConfirmPop(yztmsg, wztmsg, isForce = false) {
      var yzNum = this.pitchNums.filter((d) => {
          return !(d.my_answer instanceof Array
            ? d.my_answer.join()
            : d.my_answer);
        }).length,
        wzNum = this.topicParam.topicList.length - yzNum;
      if (
        localStorage.getItem("user_id") > 0 &&
        (this.detailList.end_time || 0) > 0 &&
        this.detailList.end_time < new Date().getTime() / 1000
      ) {
        try {
          this.$refs.CountDown.suspend = true;
        } catch (e) {}
        if (!yztmsg)
          yztmsg =
            this.examType +
            "【" +
            this.detailList.title +
            "】结束时间为" +
            this.detailList.end_time_f +
            "，是否提交答案？";
        if (!wztmsg)
          wztmsg =
            this.examType +
            "【" +
            this.detailList.title +
            "】结束时间为" +
            this.detailList.end_time_f +
            "，你来迟了哦~";
      }
      if ((isForce || yzNum > 0) && yztmsg) {
        this.$confirm(yztmsg, "提示", {
          confirmButtonText: "提交答案",
          cancelButtonText: "离开页面",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.confirmPop();
          })
          .catch(() => {
            // this.$confirm("离开页面后，答题信息将删除，是否离开页面？", '提示', {
            //   confirmButtonText: '提交答案',
            //   cancelButtonText: '离开页面',
            //   closeOnClickModal:false,
            //   type: 'warning'
            // }).then(() => {
            //   this.confirmPop();
            // }).catch(() => {
            console.log("关闭页面-------------");
            this.LocalStorageCurrent(false);
            // this.$routerGo(-1);
            window.close();
            // })
          });
      } else if (wztmsg) {
        this.$confirm(wztmsg, "提示", {
          showClose: false,
          cancelButtonText: "离开页面",
          closeOnClickModal: false,
          showConfirmButton: false,
          type: "error",
        })
          .then(() => {
            this.LocalStorageCurrent(false);
            this.$routerGo(-1);
          })
          .catch(() => {
            this.LocalStorageCurrent(false);
            console.log("关闭页面===========");
            window.close();
            this.$routerGo(-1);
          });
      }
    },
    LocalStorageCurrent: async function (t) {
      if (this.noTime) {
        return;
      }
      var answer_type = this.$route.query.answer_type || "";
      var currentTime = new Date().getTime();
      var obj;
      var key =
        "dati-" +
        Base64.encode(
          answer_type +
            "" +
            (this.examType || "o") +
            "::" +
            this.id +
            ":" +
            localStorage.getItem("user_id")
        );
      if (t === true) {
        if (
          localStorage.getItem("user_id") > 0 &&
          this.headrBtn === "提交" &&
          this.cancelButtonText &&
          !this.log_id
        ) {
          if (this.detailList.duration > 0 && !this.noTime) {
            var start_time =
              this.start_time !== undefined
                ? this.start_time
                : (currentTime / 1000 - this.CurrentDuration) * 1000;
            obj = {
              currentTime: currentTime,
              start_time: start_time,
              answer_time: this.answer_time,
              duration: this.CurrentDuration, //剩余时间（秒）
              answers: this.answers,
            };
            localStorage.setItem(
              key,
              this.$tool.strtr(
                Base64.encode(JSON.stringify(obj)),
                this.leftKey,
                this.rightKey
              )
            );
            var _formData = Object.assign({}, this.userAuth, {
              id: this.id,
              start_time: start_time,
              currentTime: currentTime,
              answer_time: this.answer_time,
              answers: this.answers,
              answer_type: this.$route.query.answer_type || "",
              duration: this.CurrentDuration, //剩余时间（秒）
              type: this.examTypeC,
            });
            await bisaianswercache(_formData);
          }
        }
      } else if (t === false || t === 0) {
        localStorage.removeItem(key);
        if (t === false) {
          await bisaianswercache(
            Object.assign(
              {
                id: this.id,
                type: this.examTypeC,
                isGet: -1,
              },
              this.userAuth
            )
          );
        }
        this.userAuth = {};
      } else {
        var res = await bisaianswercache({
          id: this.id,
          type: this.examTypeC,
          isGet: 1,
        }).catch();
        var data = ((res || {}).data || {}).data || localStorage.getItem(key);
        // console.log(data,"dataaaaa")
        if (data) {
          obj = this.$tool.strtr(data, this.leftKey, this.rightKey);
          // console.log(obj,"揭密密密")
        }
        obj = obj ? Base64.decode(obj) : "";
        if (obj) {
          obj = JSON.parse(obj);
          console.log(obj, "111111");
          console.log(obj.duration, "111111");
          console.log(this.detailList.duration, "22222");

          this.timeInfos = [
            Number(obj.duration) < this.detailList.duration
              ? Number(obj.duration)
              : this.detailList.duration,
            0,
          ];
          console.log(this.timeInfos, "timeInfos");
        }
        this.oldData = obj || {};
        return obj;
      }
    },
    LocalStorageSet(obj) {
      if (this.noTime) {
        return;
      }
      obj = obj || this.oldData || {};
      var _duration = 0,
        duration = this.detailList.duration,
        currentTime = new Date().getTime(),
        answers = {},
        _s =
          this.detailList.end_time > 0
            ? Math.max(
                parseInt(this.detailList.end_time - currentTime / 1000),
                0
              )
            : 0;
      if (
        duration === 0 &&
        _s > 0 &&
        ((this.detailList.start_time > 0 &&
          this.detailList.start_time * 1000 < currentTime) ||
          !(this.detailList.start_time > 0))
      ) {
        duration = _s;
      }
      if (obj.start_time) {
        this.start_time = parseInt(obj.start_time);
        this.answer_time = parseInt(obj.answer_time);
        if (duration > 0 && this.detailList.property + "" === "1" && _s > 0) {
          _duration = Math.min(_s, duration - this.answer_time);
        } else {
          if (this.answer_time >= 0) {
            // _duration = duration - this.answer_time;
            _duration = duration;
          } else if (duration > 0) {
            _duration =
              duration -
              parseInt(
                ((obj.currentTime || currentTime) - this.start_time) / 1000
              );
          } else {
            _duration = obj.duration || duration;
          }
        }
        for (var i = 0; i < (obj.answers || []).length; i++) {
          if (
            (obj.answers[i] || {}).language !== undefined &&
            (obj.answers[i].answer || "")
          ) {
            obj.answers[i].answer = this.$tool.htmlspecialchars_decode(
              obj.answers[i].answer
            );
          }
          answers[obj.answers[i].quest_id] = obj.answers[i];
        }
        this.answers = answers;
        if (this.detailList.activity_status === -1) {
          this.$nextTick(() => {
            this.statusTopic = "preview";
            this.$refs.CountDown && (this.$refs.CountDown.suspend = true);
            this.$message.error(
              this.detailList.activity_msg ||
                this.detailList.type_title + "已被关闭！"
            );
            this.confirmPop();
          });
        }
      } else {
        if (duration > 0 && this.detailList.property + "" === "1" && _s > 0) {
          _duration = Math.min(_s, duration);
        } else {
          _duration = duration;
        }
      }
      if (duration > 0) {
        // this.CurrentDuration = _duration > 0 ? _duration : 0;
        // this.timeInfos = [this.CurrentDuration, 0];
        console.log(obj, "obj");
        console.log(this.detailList, "detailList");
        if (obj && Object.keys(obj).length > 0) {
          console.log(obj, "1111");
          // Number(obj.duration)
          this.timeInfos = [
            Number(obj.duration) < this.detailList.duration
              ? Number(obj.duration)
              : this.detailList.duration,
            0,
          ];
          console.log(this.timeInfos, "============");
        } else {
          this.CurrentDuration = _duration > 0 ? _duration : 0;
          console.log(this.CurrentDuration, "this.CurrentDuration");
          this.timeInfos = [this.CurrentDuration, 0];
        }

        // try {
        //   this.$refs.CountDown.timeInfos = this.timeInfos;
        // } catch (e) {}
        // this.$refs.CountDown.mistiming();
      }
    },
    getBisaidetail() {
      bisaidetail(Object.assign({}, this.$route.query, { id: this.id }))
        .then((res) => {
          // console.log(res,"resssssssss")
          let info = res.data.info;
          console.log(info, "不知道什么接口");

          this.noTime =
            info.noTime === undefined || info.noTime === null
              ? !(info.duration > 0)
              : info.noTime !== true;
          this.detailList = {
            type_title: info.type_title,
            diff_title: info.diff_title,
            activity_status: info.activity_status,
            user_real_name: info.user_name,
            title: info.title,
            property: info.property,
            start_time_f: info.start_time_f,
            end_time_f: info.end_time_f,
            start_time: info.start_time,
            end_time: info.end_time,
            log_num: info.count,
            jige_total: info.jige,
            duration: info.duration,
            questions_list: res.data.questions,
            questions_notype_list: res.data.questions_notype_list,
            // questions_notype_list:res.data.questions_notype_list.sort((a, b) => a.id - b.id),
            course_id: info.course_id || 0,
          };
          this.questionsNum = res.data.questions.length;
          this.topicParam = {
            // topicList: [
            //   ...info.questions
            // ],
            // topicList: [...res.data.questions[0]._list],
            topicList: [...res.data.questions.map((v) => v._list).flat()],
            knowledgeTag: info.knows,
          };
          console.log(this.detailList, "this.detailList");
          // console.log(this.topicParam ,"-----------this.topicParam this.topicParam ")
          //如果答过题，就显示答题结果
          if (info.log_id) {
            this.log_id = info.log_id;
            this.log_answer(info.log_id);
          } else {
            // this.socketListenPath =
            //   info.socketUrl ||
            //   "api/v1_0_0.question_other/detail/id/" + this.id;
            this.confirmAnswer();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStagingAnswer() {
      let data = {
        other_id: this.id,
        other_type: "other",
      };
      this.$http
        .post("/xapi/question.question/getQuestionTemp", data)
        .then((res) => {
          // console.log(res, "上一次暂存的作业");

          if (res.data && res.data.length > 0) {
            res.data.flat().forEach((v) => {
              v.last_answer = v.answer;
            });
            this.$set(this.stagingAnswerList, "stagingAnswerList", res.data);
            console.log(
              this.stagingAnswerList,
              "this.stagingAnswerList上一次暂存的作业"
            );
          } else {
            console.log("无答案");
            this.$set(this.stagingAnswerList, "stagingAnswerList", []);
            console.log(this.stagingAnswerList, "无答案");
          }
        })
        .finally(() => {});
    },
    async confirmAnswer() {
      var obj = await this.LocalStorageCurrent();
      if (!obj.start_time) {
        if (this.detailList.activity_status === -1) {
          return this.$alert(this.detailList.type_title + "已被关闭！").then(
            () => {
              this.$routerGo(-1);
            }
          );
        }
      }
      this.$confirm("是否确认开始答题?", "提示", {
        confirmButtonText: "开始答题",
        cancelButtonText: "离开页面",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.cancelButtonText = true;
          this.start_time = new Date().getTime();
          this.startTimeLine = new Date().getTime() / 1000;
          this.LocalStorageSet(obj);
          this.startExam = true;
          this.callApi();
        })
        .catch(() => {
          // this.$routerGo(-1);
          console.log("离开页面");
          // this.$router.go(-1);
          window.close();
        });
    },
    getDetailList() {
      detailList({ id: this.id })
        .then((res) => {
          if ((res.data.type_title || "").toString() === "作业") {
            this.noTime = true;
          }
          this.detailList = res.data;
          // console.log(this.detailList,"this.detailList")
          // const processedData = [];
          // this.detailList.questions_list.forEach(item => {
          //       const newItem = { ...item };
          //       newItem._list.forEach(subItem => {
          //           processedData.push({ ...newItem, ...subItem });
          //       });
          //   });
          //   console.log(processedData,"console.log(processedData);");
          //   this.$set(this.detailList,'processedData',processedData)
          // this.detailList.questions_notype_list=[{
          //   _list:this.detailList.questions_notype_list
          // }]
          console.log(this.detailList, "this.detailList");
          this.$store.state.answerCorrect = _.cloneDeep(
            res.data.questions_list
          );
          this.questionsNum = res.data.questions_list.length;
          this.topicParam = {
            topicList: [...res.data.questions],
            knowledgeTag: res.data.know_format,
          };
          if (!this.noTime && !this.is_log_answer) {
            // if (res.data.socketUrl) this.socketListenPath = res.data.socketUrl;
            this.confirmAnswer();
          } else {
            this.cancelButtonText = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.SubmitPop.show = true;
      this.$refs.CountDown.suspend = true;
    },
    cancelPop(type) {
      if (type === "cancel" && this.routeLeave instanceof Function) {
        this.routeLeave(true);
      } else {
        this.$refs.SubmitPop.show = false;
        this.$refs.SubmitPopExit.show = false;
        this.$refs.CountDown.suspend = false;
      }
      this.routeLeave = 0;
    },

    //确认提交
    confirmPop() {
      this.$refs.SubmitPop.show = false;
      this.$refs.SubmitPopExit.show = false;

      this.loading = true;
      this.loadingText = "提交中...";

      let api = otherAnswer;
      if (this.examTypes.indexOf(this.examType) > -1) {
        api = bisaianswer;
      }
      console.log(this.answers, "this.answersthis.answers");
      // return
      let $data = Object.assign({}, this.$route.query, {
        id: this.id,
        start_time: this.start_time / 1000,
        last_commit_time: new Date().getTime() / 1000,
        answers: this.answers,
        answer_type: this.$route.query.answer_type || "",
        noTime: this.noTime,
      });
      api($data)
        .then((res) => {
          this.LocalStorageCurrent(false);
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.cancelButtonText = true;
          this.log_id = res.data.id;
          this.$handleRoute(
            {
              log_id: res.data.id,
              id: this.id,
              course_id: this.detailList.course_id || null,
              // type: this.examType,
              style: this.examType,
            },
            this.examTypes.indexOf(this.examType) > -1
              ? "matchTiDanRecord"
              : "/problemList/tiDanRecord",
            true
          );
        })
        .finally(() => {
          this.loadingText = "";
          this.loading = false;
        });
    },
    //查看详情状态
    log_answer(id) {
      question_log_answer({ log_id: id, user_id: this.userInfo.id })
        .then((res) => {
          this.deploy.isAnswer = true;
          this.discussShow = true;
          let data = Object.values(res.data.log_list);

          this.$store.state.answerData = {
            answer: res.data.log_list,
          };
          // this.$store.state.answerCorrect = _.cloneDeep(res.data.questions_list)
          this.statusTopic = "answer";
          this.loading = false;
          this.loadingText = "";
          this.headrBtn = "查看结果";
          let pitchNums = [];
          data.map((v) => {
            if (!!v.answer) {
              pitchNums.push(v.question_id);
            }
          });
          this.setPitchNums(pitchNums);
        })
        .finally(() => {
          this.loadingText = "";
          this.loading = false;
        });
    },
    //滚动到点击的题目
    topicClick(selector) {
      console.log(selector, "selectorselector");
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    //右边题目高亮
    setPitchNums(pitchNums) {
      console.log(pitchNums, "pitchNums");
      this.pitchNums = pitchNums;
    },
    setAnswers(answers) {
      // console.log(answers, "传出来答案2222");
      this.answers = answers;
    },
  },
};
</script>

<style lang="less" scoped>
.practiceExam {
  ::v-deep {
    .el-backtop {
      width: 56px;
      height: 56px;
    }
  }
  .go-top {
    .el-icon-caret-top {
      font-size: 26px;
      margin-top: -6px;
    }
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  .box {
    display: flex;
    justify-content: space-between;
    .content-left {
      // flex: 1;
      // width: calc(100vw-400px);
      // width: 900px;
      width: 100%;
      .course-title {
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
          }
          .el-icon-edit-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
        }
      }
      .operation {
        margin-left: 1rem;
      }
    }
    .contDown {
      display: flex;
      margin-right: 40px;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #303030;
      // position: fixed;
      // width: 345px;
      // margin-bottom: 40px;
      .time {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        // margin: 2rem;
      }
    }
    .content-right {
      position: relative;
      margin-top: 20px;
      // position: fixed;
      width: 27%;
      // right: 0;
      //.fixed {
      // position: fixed;
      // width: 350px;
      // height: 76vh;
      // overflow-y: auto;
      // background-color: #f5f5f5;
      // }
      .contDown {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: #303030;
        // position: fixed;
        // width: 345px;
        // margin-bottom: 40px;
        .time {
          text-align: center;
          font-size: 52px;
          font-weight: 600;
          // margin: 2rem;
        }
      }
      .recommend {
        margin-top: 44px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        padding: 1rem;
        font-size: 14px;
      }
    }
  }
}
.sameStyle {
  min-width: 80px;
  color: #00957e !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
}
::v-deep .number{
  color: #00957e !important; ;
}
</style>