<template>
    <!-- <div class="scroll-box" style="position:relative;"> -->
    <div class="scroll-box" style="position:relative;">
      <!-- <div class="scroll-fixed" :style="fixedStyle"> -->
      <div class="scroll-fixed" >
        <!-- <slot name="fixed"></slot> -->
      </div>
      <!-- <div class="scroll-body" :style="bodyStyle"> -->
      <div class="scroll-body">
        <slot></slot>
      </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
  props:{
    bodyStyle:{
      type:[Object,String],
      default(){return null;}
    },
    scrollTarget:{
      type:[Object,String],
      default(){return null;}
    },
    fixedStyle:{
      type:Object,
      default(){return null;}
    },
    fixedMethod:{
      type:Function,
      default(){return null;}
    },
  },
  data(){
    return {
      minHeight:null,
      scrollTop:0,
      scrollLeft:0,
      currentTarget:null,
      currentTop:0,
      currentLeft:0,
    };
  },
  mounted () {
    this.initDocument();
  },
  beforeDestroy () {
    if(this.currentTarget)$(this.currentTarget).get(0).addEventListener("scroll", this.handleScroll);
  },
  methods:{
    initDocument(){
      var currentTarget = this.scrollTarget;
      if(!currentTarget){
        var parents = $(this.$el).parents();
        for (let i = 0; i < parents.length; i++) {
          let overflow = $(parents[i]).css('overflow');
          let overflowX = $(parents[i]).css('overflow-x');
          let overflowY = $(parents[i]).css('overflow-y');
          if(overflow === 'auto' || overflow === 'scroll' || overflowX === 'auto' || overflowX === 'scroll' || overflowY === 'auto' || overflowY === 'scroll'){
            currentTarget = parents[i];
            break;
          }
        }
      }
      if(currentTarget){
        try{
          $(this.currentTarget).get(0).addEventListener("scroll", this.handleScroll);
        }catch (e) { }
      }
      this.currentTarget = currentTarget;
      if(currentTarget){
        this.currentTop = $(this.$el).offset().top;
        this.currentLeft = $(this.$el).offset().left;
        $(this.currentTarget).get(0).addEventListener("scroll", this.handleScroll );
        this.$nextTick(()=>{this.handleScroll();})
      }
    },
    handleScroll(event){
      var target = (event || {}).target;
      if(!target && this.$el){
        target = this.currentTarget;
      }
      var $position = $(this.$el).children('.scroll-fixed:eq(0)');
      this.minHeight = $position.height();
      $(this.$el).css('min-height',this.minHeight+'px');
      this.scrollTop = $(target).scrollTop();
      this.scrollLeft = $(target).scrollLeft();
      var height = $(this.$el).height();
      var width = $(this.$el).width();
      var currentTop = $(this.$el).offset().top;
      var currentLeft = $(this.$el).offset().left;
      var $bottom = this.fixedStyle.bottom !== undefined ? parseInt(this.fixedStyle.bottom || 0) : null;
      var $top = this.fixedStyle.top !== undefined ? parseInt(this.fixedStyle.top || 0) : null;
      var $right = this.fixedStyle.right !== undefined ? parseInt(this.fixedStyle.right || 0) : null;
      var $left = this.fixedStyle.left !== undefined ? parseInt(this.fixedStyle.left || 0) : null;
      var bottom = $bottom !== null ? Math.max($bottom , (this.scrollTop + (window.screen.height || 0)) - height - $bottom) : null;
      var top = $top !== null ? Math.max($top , this.scrollTop + $top) : null;
      // var right = $right !== null ? Math.max($right , this.scrollLeft - width - $right) : null;
      var right = $right !== null ? $right : null;
      var left = $left !== null ? Math.max($left , this.scrollLeft - $left) : null;
      let childrens = $(this.$el).children('.scroll-body:eq(0)').children();
      let $index = 0;
      for (let i = 0; i < childrens.length; i++) {
        var $this = $(childrens[i])
        if($this.height() + $this.offset().top <= window.screen.height){
          $index = i;
        }
      }
      if(bottom !== null){
        $position.css('bottom' , bottom+'px');
      }
      if(top !== null){
        //console.log("top" , top , this.minHeight , $(this.$el).offset() , this.currentTop);
        $position.css('top' , top+'px');
      }
      if(right !== null){
        $position.css('right' , right+'px');
      }
      if(left !== null){
        $position.css('bottom' , left+'px');
      }
      this.fixedMethod && this.fixedMethod({$index,scrollTop:this.scrollTop,scrollLeft:this.scrollLeft,bottom,top,right,left});
    },
  }
}
</script>

<style scoped lang="less">
.scroll-box::v-deep{
  position:relative;
  overflow: hidden;
  display: block;
  > .scroll-fixed{
    // position: absolute;
  }
  > .scroll-body{
    width:100%;
  }
}
</style>